import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ScrollService } from "../../../Core/Services/Scroll/scroll.service";
import { RouterLink } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: "[app-welcome-section]",
  standalone: true,
  imports: [TranslateModule, RouterLink, CommonModule],
  templateUrl: "./welcome-section.component.html",
  styleUrl: "./welcome-section.component.css",
})
export class WelcomeSectionComponent implements OnInit, OnDestroy {
  welcomeText?: SafeHtml;
  blogtext?: SafeHtml;
  hasBlog = false;
  onLangChange?: Subscription;
  constructor(
    public scrollService: ScrollService,
    private apiservice: ApiService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.onLangChange = this.translateService.onLangChange.subscribe({
      next: () => {
        this.apiCall();
      },
    });
    this.apiCall();
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }

  apiCall() {
    this.apiservice.getWebText("homepage_text").subscribe({
      next: (response) => {
        if (response.result) {
          this.welcomeText = this.sanitizer.bypassSecurityTrustHtml(
            response.data[0].html
          );
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
    this.apiservice.getWebText("fronted-blog").subscribe({
      next: (response) => {
        if (response.result || false) {
          this.blogtext = this.sanitizer.bypassSecurityTrustHtml(
            response.data[0].html
          );
          if (response.data[0].html) {
            this.hasBlog = true;
          }
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
